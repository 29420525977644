.table_details {
    color: #667085;
    font-weight: 500;
}

.table {
    font-size: 14px;
    border: 1px solid #efefef;
    border-radius: 20px;
}

.table-icon {
    width: 40%;
    background: none;
    border: none;
}

.remove-date-button {
    border: none;
    background: none;
}

.edit-table-button {
    border-radius: 10px;
    border: none;
    padding: 8px 32px;
    font-weight: 600;
    cursor: pointer;
}

.edit-table-button:hover {
    background-color: #CBD0DC;
}

#add-vaccine {
    margin-left: 15px;
    margin-bottom: 5px;
}

.table-container {
    max-height: 1500px;
    overflow-y: auto;
    margin-top: 20px;
    border: 1px solid #CBD0DC;
    border-radius: 8px;
}

/* Make the table header sticky */
.table thead th {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 10;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Custom scrollbar for the table */
.table-container::-webkit-scrollbar {
    width: 12px; /* Adjust the width of the scrollbar */
}

.table-container::-webkit-scrollbar-thumb {
    background-color: #4D77FF; /* Scrollbar thumb color */
    border-radius: 6px; /* Rounded scrollbar thumb */
}

.table-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of the scrollbar track */
    border-radius: 6px;
}

/* Alternating colors for vaccine groups */
.vaccine-group:nth-child(odd) {
    background-color: #F9FAFD;
}

.vaccine-group:nth-child(even) {
    background-color: #FFFFFF;
}

/* General styles */
.input-table {
    width: 100%;
    padding: 4px;
    border: 1px solid #efefef;
    border-radius: 4px;
}

input[type="text"], input[type="date"] {
    font-size: 14px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #dcdcdc;
    width: 90%;
}

input[type="text"]:focus, input[type="date"]:focus {
    outline: none;
    border: 1px solid #4D77FF;
    box-shadow: 0 0 5px rgba(77, 119, 255, 0.5);
}

#vaccine-name {
    color: #0061FF;
}