.slide-container {
    background-image: linear-gradient(to right, #012ECF, #0DC1CC);
    height: fit-content;
    width: 35vh;  /* Initial size */
    border-radius: 20px;
    font-family: "Rubik", sans-serif;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slide-title {
    color: white;
    font-size: 18px;  /* Initial size */
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
}

.slide-info {
    color: white;
    font-size: 12px;  /* Initial size */
    font-weight: 400;
}

/* Medium screens (laptops, tablets, etc.) */
@media (max-width: 1026px) {
    .slide-container {
        width: 18vw;  /* Slightly reduce the width */
        padding: 8px;  /* Reduce padding */
        border-radius: 18px;
    }

    .slide-title {
        font-size: 10px;  /* Reduce title font size */
    }

    .slide-info {
        font-size: 8px;  /* Reduce info font size */
    }
}

/* Smaller tablets and large mobile devices */
@media (max-width: 768px) {
    .slide-container {
        width: 25vh;  /* Further reduce the width */
        padding: 10px;  /* Reduce padding further */
        border-radius: 15px;
    }

    .slide-title {
        font-size: 14px;  /* Adjust title font size */
    }

    .slide-info {
        font-size: 9px;  /* Adjust info font size */
    }
}

@media (max-width: 768px) {
    .slide-container {
        width: 30vh;  /* Further reduce the width */
        padding: 10px;  /* Reduce padding further */
        border-radius: 15px;
    }

    .slide-title {
        font-size: 14px;  /* Adjust title font size */
    }

    .slide-info {
        font-size: 9px;  /* Adjust info font size */
    }
}

/* Small mobile devices */
@media (max-width: 480px) {
    
}
