.but {
  border-radius: 10px;
  gap: 10px;
  padding: 12px 22px 12px 22px;
  border-color: transparent;
  font-weight: 500;
  font-size: 14px;

}

#navlinks {

}

#signin {
  background-color: transparent;
}

#signup {
  background: #4D77FF;
  color: white
}

#signin:hover {
  background-color: gray;
}

#signup:hover {
  background-color: darkblue;
}

#navlinks {
  font-weight: 500;
  font-size: 14px;
  gap: 35px;
  width: max-content;
  align-self: center;
  
}

@media (max-width: 480px) {

  .mx-auto#navlinks {
    gap: 10px;
  }

  .but {
    display: none;
  }

}

@media (max-width: 1200px) {
  .home-navbar {
    width: 100%;
  }
}