.card {
    border-color: #4D77FF;
    border-top-width: 4px;
    width: 19vw;
    height: 18vh;
    border-bottom: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    align-items: center;
    justify-content: center;
    text-align: center;

}

.stat {
    font-size: 25px;
    font-weight: 700;
    color: #4D77FF;

}
.card-body {
    margin: 1px 5px 5px 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
}

#main{
    margin-top: 20px;
    font-weight: 900;
    font-size: 16px;
}

.text-secondary {
    font-weight: 500;
    font-size: 12px;
}

.icon {
    align-items: center;
    max-width: fit-content;
}

@media (max-width: 1024px) {
    .card {
        width: 24vw;
        height: 18vh;
        
    }

}

@media (max-width: 768px) {
    .card {
        width: 30vw;
        height: 20vh;
        
    }

    .stat {
        margin-top: 1vh;
        font-size: 25px;
        text-align: center;
    }

    #main {
        text-align: center;
        font-size: 16px;
    }

}

@media (max-width: 480px) {
    .card {
        width: 35vw;
        height: 20vh;
        
    }
    #main {
        text-align: center;
        font-size: 16px;
    }

    .stat {
        font-size: 20px;
    }
   
}
