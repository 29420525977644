.wrapper {
    display: flex;
    flex-direction: row;
    gap: 6vw;
    justify-content: center;
}

@media (max-width: 1024px) {


}

@media (max-width: 768px) {
    .wrapper {
        flex-direction: column;
    }

}

@media (max-width: 480px) {
   
}
