.immune-wrapper {
    background-image: url(../assets/Group\ 6.svg);
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center;  
    width: 100%;  
    aspect-ratio: 2 / 1;  /* Replace with your SVG's width/height ratio */
}



@media (max-width: 1024px) {
    .immune-wrapper {
        max-width: 100%;  
        max-height: 100%;
    }

}

@media (max-width: 768px) {
    

}

@media (max-width: 480px) {
   
}
