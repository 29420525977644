.slide-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    margin-left: 20vw;
}

@media (max-width: 768px) {
    .slide-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2vh;
        margin-left: 35vw;
    }
}