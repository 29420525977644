.wrapper-upload {
    display: flex;
    
    align-items: center;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;

}

#browse-files {
    color: #54575C;
    font-size: 22px;
    font-weight: 500;
    padding: 8px 32px;
    cursor: pointer;
    border-radius: 16px;
    border: 2px solid #CBD0DC;
}

#browse-files:hover {
    background-color: #CBD0DC;
}

.upload-header {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 66.67%;
    border-bottom: 3px solid #CBD0DC;

}

#upload-logo {
    padding: 10px;
    margin-right: 1.5vw;
}

.upload-text {
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.upload-text #top-text {
    font-size: 34px;
    color: #292D32;

}

.upload-text #lower-text {
    font-size: 29px;
    color: #A9ACB4
}

.drag-drop-area {
    border: 4px dashed #CBD0DC;
    border-radius: 26px;
    text-align: center;
    cursor: pointer;
    width: 63%;
    margin-top: 2.5%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vh;
    padding-top: 4vh;
    padding-bottom: 4vh;
    margin-bottom: 5vh;
    
}



.drag-and-drop-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    font-weight: 500;
}

#drag-and-drop-top-text {
    font-size: 30px;
    color: #292D32;

}

#drag-and-drop-bot-text {
    font-size: 26px;
    color: #A9ACB4;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #76c7c0;
    transition: width 0.5s ease-in-out;
  }
  
  .stage-text {
    font-size: 26px;
    font-weight: 500;
  }

  .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
}

.spinner {
    border: 8px solid #CBD0DC; 
    border-top: 8px solid #375EF9; 
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.selected-files {
    width: 63%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: #292D32;

}

.files {
    width: 100%;
}

#convert-button {
    background-color: #4D77FF;
    color: #FFFFFF;
    border: none;
    font-size: 22px;
    font-weight: 500;
    padding: 8px 64px;
    cursor: pointer;
    border-radius: 10px;
    margin-left: auto;
    margin-bottom: 4vh

}

#convert-button:hover {
    background-color: #375EF9;
}

.button-container {
    width: 63%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-bottom: 5vh;
}

.table-headers {
    border: none;

}
/* Alternating colors for vaccine groups */
.vaccine-group:nth-child(odd) {
    background-color: #F9FAFD;
}

.vaccine-group:nth-child(even) {
    background-color: #FFFFFF;
}

/* Table styling remains */
table {
    border: 1px solid #CBD0DC;
    border-radius: 8px;
    overflow: hidden;
}

.table-container {
    max-height: 500px;
    overflow-y: auto;
    margin-top: 20px;
    border: 1px solid #CBD0DC;
    border-radius: 8px;
}

.input-table {
    width: 100%;
    background: none;
    border: none;
}

.table-component {
    width: 68%;
}