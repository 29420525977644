.file-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #EEF1F7;
    margin-bottom: 4vh;
    border-radius: 15px;
    align-items: center;
    height: 100px;
}

.remove-file-button {
    margin-left: auto;
    border: none;
    background-color: #EEF1F7;
    margin-right: 10px;
}

.file-size {
    color: #A9ACB4;
    font-size: 18px;
}

.icon {
    width: 70%;
}

.file-icon {
    width: 50%;
    margin-left: 20px;
    height: 100px;
}

.file-details {
    display: flex;
    flex-direction: column;
}